import bbox from "@turf/bbox";
import tcenter from "@turf/center";
import { AllGeoJSON } from "@turf/helpers";
import { RGFeature, RGFeatureCollection, RGTransform } from "types/geojson";
import {
  featureToFeatureCollection,
  findPolylabel,
  rotateGeoJSON,
  rotateGeoJSONFeature,
  scaleGeoJSON,
  scaleGeoJSONFeature,
  translateGeoJSON,
  translateGeoJSONFeature,
} from "utils/geojson";

export function getBBoxPointsGeojson(
  geojson: RGFeatureCollection
): RGFeatureCollection {
  const bounds = bbox(geojson);
  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [bounds[0], bounds[1]],
        },
        properties: {
          side: "sw",
          contrarySide: "ne",
          system: "scale",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [bounds[2], bounds[1]],
        },
        properties: {
          side: "se",
          contrarySide: "nw",
          system: "scale",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [bounds[2], bounds[3]],
        },
        properties: {
          side: "ne",
          contrarySide: "sw",
          system: "scale",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [bounds[0], bounds[3]],
        },
        properties: {
          side: "nw",
          contrarySide: "se",
          system: "scale",
        },
      },
    ],
  };
}

export function getLabelPointsGeojson(
  geojson: RGFeatureCollection
): RGFeatureCollection {
  return {
    ...geojson,
    features: geojson.features.map((f: RGFeature) => {
      return {
        geometry: {
          type: 'Point',
          coordinates: findPolylabel(f)
        },
        properties: {
          system: 'feature-label',
          'system-style': f.properties['system-style']
        }
      }
    }
    )
  } as unknown as RGFeatureCollection
}

export function getBBoxPolygonFeature(
  geojson: RGFeatureCollection | RGFeature
): RGFeature {
  const bounds = bbox(geojson);
  return {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [bounds[0], bounds[1]], //minx miny
          [bounds[2], bounds[1]], //maxx miny
          [bounds[2], bounds[3]], //maxx maxy
          [bounds[0], bounds[3]], //minx maxy
          [bounds[0], bounds[1]],
        ],
      ],
    },
    properties: {
      system: "bounding-box",
    },
  };
}

export function getBBoxPolygonGeojson(
  geojson: RGFeatureCollection
): RGFeatureCollection {
  return {
    type: "FeatureCollection",
    features: [getBBoxPolygonFeature(geojson)],
  };
}

export function getRotationPointGeojson(
  geojson: RGFeatureCollection
): RGFeatureCollection {
  const bounds = bbox(geojson);
  const center = getCenter(geojson);

  const coordinates = [
    bounds[0] + (bounds[2] - bounds[0]) / 2,
    bounds[3] + (bounds[3] - bounds[1]) / 8,
  ];

  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates,
        },
        properties: {
          origin: "centroid",
          system: "rotate",
        },
      },
      // {
      //   type: "Feature",
      //   geometry: {
      //     type: "Point",
      //     coordinates: center,
      //   },
      //   properties: {
      //     origin: "centroid",
      //     system: "rotate",
      //   },
      // },
    ],
  };
}

export function restoreGeojson(data: RGFeatureCollection): RGFeatureCollection {
  return {
    type: "FeatureCollection",
    features: data._features,
  };
}

export function filterOutSystemFeatures(
  data: RGFeatureCollection
): RGFeatureCollection {
  return {
    ...data,
    features: data.features.filter((ft) => !ft.properties?.system),
  };
}

export function filterOnlyBBoxFeatures(
  data: RGFeatureCollection
): RGFeatureCollection {
  return {
    ...data,
    features: data.features.filter((ft) => ft.properties?.system === "scale"),
  };
}

export function filterOnlyRotationFeatures(
  data: RGFeatureCollection
): RGFeatureCollection {
  return {
    ...data,
    features: data.features.filter((ft) => ft.properties?.system === "rotate"),
  };
}

export function getExportData(data: RGFeatureCollection) {
  const transformed = {
    ...data,
    features: data.features.map((ft) => ft),
  };

  return transformed;
}

export function getSourceDataFeature(feature: RGFeature, selected: boolean) {
  const translated = featureToFeatureCollection(feature);

  const addSystemProperties = (features: RGFeature[]) =>
    features.map((d) => ({
      ...d,
      properties: {
        ...d.properties,
        selected,
      },
    }));

  return addSystemProperties([
    ...translated.features,
    ...(selected
      ? [
        ...getBBoxPointsGeojson(translated).features,
        ...getBBoxPolygonGeojson(translated).features,
        ...getRotationPointGeojson(translated).features,
        ...getLabelPointsGeojson(translated).features
      ]
      : []),
  ]);
}

export function getSourceData(data: RGFeatureCollection, selectedID?: string) {
  const transformed = {
    ...data,
    features: data.features.reduce(
      (r, ft) => [...r, ...getSourceDataFeature(ft, ft.id === selectedID)],
      [] as RGFeature[]
    ),
  };

  const addSystemProperties = (features: RGFeature[]) =>
    features.map((d) => ({
      ...d,
      properties: {
        ...d.properties,
        selected: d.properties.selected || false,
      },
    }));

  const translatedWithoutSystemFeatures = filterOutSystemFeatures(transformed);

  return {
    ...data,
    _features: data.features,
    features: addSystemProperties([
      ...(transformed.features as RGFeature[]),
      ...getBBoxPointsGeojson(translatedWithoutSystemFeatures).features,
      ...getBBoxPolygonGeojson(translatedWithoutSystemFeatures).features,
      ...getRotationPointGeojson(translatedWithoutSystemFeatures).features,
      ...getLabelPointsGeojson(translatedWithoutSystemFeatures).features
    ]),
  };
}

export function updateSource(
  data: RGFeatureCollection,
  transform: Partial<RGTransform>
): RGFeatureCollection {
  const center = getCenter(data);
  let isNorthLimit = false;
  let isSouthLimit = false;
  let isEastLimit = false;
  let isWestLimit = false;
  const boundings = bbox(data);
  const northLatLimit = 85.1;
  const southLatLimit = -82;
  const maxNorthBounding = boundings[3];
  const maxSouthBounding = boundings[1];
  const westLongLimit = -539;
  const eastLongLimit = 460;
  const maxWestLongBound = boundings[0];
  const maxEastLongBound = boundings[2];
  if (transform.translate) {
    const yTranslation = transform.translate[1];
    const xTranslation = transform.translate[0];
    isNorthLimit = (maxNorthBounding + yTranslation) >= northLatLimit;
    isSouthLimit = (maxSouthBounding + yTranslation) <= southLatLimit;
    isWestLimit = (maxWestLongBound + xTranslation) <= westLongLimit;
    isEastLimit = (maxEastLongBound + xTranslation) >= eastLongLimit;
    const isMovingUp = yTranslation > 0;
    const isMovingRight = xTranslation > 0;
    if (isNorthLimit || isSouthLimit || isEastLimit || isWestLimit) {
      if ((isNorthLimit && isMovingUp) || (isSouthLimit && !isMovingUp)) {
        transform.translate[1] = isNorthLimit
          ? northLatLimit - maxNorthBounding
          : southLatLimit - maxSouthBounding;
      }
      if ((isEastLimit && isMovingRight) || (isWestLimit && !isMovingRight)) {
        transform.translate[0] = isEastLimit ? eastLongLimit - maxEastLongBound : westLongLimit - maxWestLongBound;
      }
    }
  }
  const scaled = scaleGeoJSON(data, transform.scale || 1, center);

  const translated = translateGeoJSON(scaled, transform.translate || [0, 0]);

  const centroid = getCenter(translated);

  const rotated = rotateGeoJSON(translated, transform.rotate || 0, centroid);

  return rotated;
}

export function updateSourceFeature(
  data: RGFeatureCollection,
  selectedID: string,
  transform: Partial<RGTransform>
) {
  return {
    ...data,
    features: data.features.map((ft) => {
      if (selectedID === ft.id) {
        const ftCenter = getCenter(getBBoxPolygonFeature(ft));

        const scaled = scaleGeoJSONFeature(ft, transform.scale || 1, ftCenter);

        const translated = translateGeoJSONFeature(
          scaled,
          transform.translate || [0, 0]
        );

        const centroid = getCenter(translated);

        const rotated = rotateGeoJSONFeature(
          translated,
          transform.rotate || 0,
          centroid
        );

        return rotated;
      }
      return ft;
    }),
  };
}

export function getBBoxPoints(geojson: RGFeatureCollection | RGFeature) {
  const bounds = bbox(geojson);

  return [
    [bounds[0], bounds[1]], //sw 0 -> 2
    [bounds[2], bounds[1]], //se 1 -> 3
    [bounds[2], bounds[3]], //ne 2 -> 0
    [bounds[0], bounds[3]], //nw 3 -> 1
  ];
}

export function getCenter(data: RGFeatureCollection | RGFeature) {
  return tcenter(data as AllGeoJSON).geometry?.coordinates || [0, 0];
}
