import {
  APIKey,
  Message,
  QueryParamDict,
  User,
  PublicUser,
  APIResponse,
  LoginResponse,
  ActionResponse,
  APIPaginatedResponse,
  UserGeojson,
} from "./types";
import { API_URL } from "settings";

export function concatQueryParams(params: QueryParamDict) {
  if (!params) return "";
  return Object.entries(params).reduce((r, [key, value]) => {
    if (!value) return r;
    let param = `${key}=${value}`;
    if (r) return `${r}&${param}`;
    return param;
  }, "");
}

async function processPaginatedQuery(response: Response) {
  const data = await response.json();

  return {
    ...data,
    pagination: {
      page: response.headers.get("page") || 0,
      pageSize: response.headers.get("pageSize") || 0,
      totalPages: response.headers.get("totalPages") || 0,
      totalRecords: response.headers.get("totalRecords") || 0,
    },
  };
}

export function postLogin(data: FormData): Promise<APIResponse<LoginResponse>> {
  return fetch(`${API_URL}app/auth/`, {
    method: "POST",
    body: data,
    redirect: "follow",
  }).then((r) => r.json());
}

export function postRegister(data: FormData): Promise<APIResponse<PublicUser>> {
  return fetch(`${API_URL}app/auth/register/`, {
    method: "POST",
    body: data,
    redirect: "follow",
  }).then((r) => r.json());
}

export function postContact(
  data: FormData
): Promise<APIResponse<ActionResponse>> {
  return fetch(`${API_URL}app/contact/`, {
    method: "POST",
    body: data,
    redirect: "follow",
  }).then((r) => r.json());
}

export function getKeys(
  token: string,
  page: number,
  pageSize: number,
  date?: string,
  name?: string
): Promise<APIPaginatedResponse<APIKey[]>> {
  return fetch(
    `${API_URL}app/apikey/?${concatQueryParams({
      page,
      pageSize,
      date,
      name,
    })}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: `Token ${token}`,
      },
    }
  ).then(processPaginatedQuery);
}

export function postKey(
  token: string,
  data: FormData
): Promise<APIResponse<APIKey>> {
  return fetch(`${API_URL}app/apikey/`, {
    method: "POST",
    body: data,
    redirect: "follow",
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((r) => r.json());
}

export function deleteKey(
  token: string,
  id: string,
): Promise<Response> {
  return fetch(`${API_URL}app/apikey/${id}/`, {
    method: "DELETE",
    redirect: "follow",
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((r) => r);
}

export function getUser(token: string, id: string): Promise<APIResponse<User>> {
  return fetch(`${API_URL}app/admin/users/${id}`, {
    method: "GET",
    redirect: "follow",
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((r) => r.json());
}

export function getUsers(
  token: string,
  page: number,
  pageSize: number,
  search?: string
): Promise<APIPaginatedResponse<User[]>> {
  return fetch(
    `${API_URL}app/admin/users/?${concatQueryParams({
      page,
      pageSize,
      search,
    })}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: `Token ${token}`,
      },
    }
  ).then(processPaginatedQuery);
}

export function patchUser(
  token: string,
  id: string,
  data: FormData
): Promise<APIResponse<User>> {
  if (data.has("is_active")) data.set("is_active", "1");
  else data.set("is_active", "0");

  if (data.has("is_staff")) data.set("is_staff", "1");
  else data.set("is_staff", "0");

  return fetch(`${API_URL}app/admin/users/${id}/`, {
    method: "PATCH",
    redirect: "follow",
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((r) => r.json());
}

export function getMessage(
  token: string,
  id: string
): Promise<APIResponse<Message>> {
  return fetch(`${API_URL}app/admin/messages/${id}`, {
    method: "GET",
    redirect: "follow",
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((r) => r.json());
}

export function getMessages(
  token: string,
  page: number,
  pageSize: number,
  search?: string
): Promise<APIPaginatedResponse<Message[]>> {
  return fetch(
    `${API_URL}app/admin/messages/?${concatQueryParams({
      page,
      pageSize,
      search,
    })}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: `Token ${token}`,
      },
    }
  ).then(processPaginatedQuery);
}

export function getUserGeojson(
  token: string,
  id: string,
  page: number,
  pageSize: number,
  date?: string,
  name?: string
): Promise<APIResponse<UserGeojson>> {
  return fetch(`${API_URL}app/user-geojson/${id}?${concatQueryParams({
    page,
    pageSize,
    date,
    name,
  })}`, {
    method: "GET",
    redirect: "follow",
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then(processPaginatedQuery);
}

export function postUserGeojson(
  token: string,
  data: Pick<UserGeojson, "name" | "geojsons">
): Promise<APIResponse<UserGeojson>> {
  return fetch(`${API_URL}app/user-geojson/`, {
    method: "POST",
    body: JSON.stringify(data),
    redirect: "follow",
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
  }).then((r) => r.json());
}

export function putUserGeojson(
  token: string,
  id: string,
  data: Pick<UserGeojson, "name" | "geojsons">
): Promise<APIResponse<UserGeojson>> {
  return fetch(`${API_URL}app/user-geojson/${id}/`, {
    method: "PUT",
    redirect: "follow",
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((r) => r.json());
}

export function deleteUserGeojson(
  token: string,
  id: string,
): Promise<Response> {
  return fetch(`${API_URL}app/user-geojson/${id}/`, {
    method: "DELETE",
    redirect: "follow",
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((r) => r);
}

export function getUserGeojsons(
  token: string,
  page: number,
  pageSize: number,
  search?: string
): Promise<APIPaginatedResponse<UserGeojson[]>> {
  return fetch(
    `${API_URL}app/user-geojson/?${concatQueryParams({
      page,
      pageSize,
      search,
    })}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: `Token ${token}`,
      },
    }
  ).then(processPaginatedQuery);
}
