import * as React from 'react';
import { useAuth } from "auth";
import { useAPI } from "hooks/api/useAPI";
import { deleteKey, getKeys, postKey } from "hooks/api/utils";
import { useMutation, useQuery, useQueryClient } from "react-query";

export function useKey(apikey: string, date?: string, name?: string) {
  const { token } = useAuth();
  const queryClient = useQueryClient();
  const [page, setPage] = React.useState(0);

  const keys = useQuery(["keys", page, date, name], () =>
    getKeys(token || "", page, 10, date, name)
  );

  const createKey = useAPI(
    (data) => postKey(token || "", data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("keys");
      },
    }
  );

  const removeKey = useMutation(
    () => deleteKey(token || "", apikey),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("keys");
        queryClient.invalidateQueries(["keys", apikey]);
      },
    }
  );

  return {
    onChangePage: setPage,
    pagination: keys?.data?.pagination,
    data: keys?.data?.data,
    createKey,
    removeKey: removeKey.mutate
  };
}
