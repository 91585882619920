import * as React from "react";
import { useAuth } from "auth";
import { useQuery } from "react-query";
import { getUsers } from "hooks/api/utils";

export function useUsers() {
  const { token } = useAuth();

  const [page, setPage] = React.useState(0);
  const [search, setSearch] = React.useState("");

  const users = useQuery(["users", page, search], () =>
    getUsers(token || "", page, 10, search)
  );

  const onChangeSearch = React.useCallback((value: string) => {
    setPage(0);
    setSearch(value);
  }, []);

  return {
    onChangePage: setPage,
    onChangeSearch: onChangeSearch,
    pagination: users?.data?.pagination,
    data: users?.data?.data,
  };
}
