import * as React from "react";
import { useLogin } from "hooks/api";
import { AuthStoreType } from "auth/types";

export const AuthStore = React.createContext<AuthStoreType>({});

interface AuthStoreProvider {
  children: React.ReactNode;
}

export function AuthStoreProvider(props: AuthStoreProvider) {
  const login = useLogin();

  const contextValue = React.useMemo(
    () => ({
      login,
      ...(login?.data || {}),
    }),
    [login]
  );

  return (
    <AuthStore.Provider value={contextValue}>
      {props.children}
    </AuthStore.Provider>
  );
}
