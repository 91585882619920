import * as React from "react";
import { AppStoreType, Location, ExportType } from "store/types";
import {
  copyTextToClipboard,
  downloadFile,
  formatPropertiesIds,
  geojson2Img,
  geojson2SVG,
  geojson2Topojson,
  getGeojson,
  getUserLocation,
  mergeGeojson,
} from "store/utils";
import {
  analyticsAddLocation,
  analyticsClearLocations,
  analyticsCopyToClipboard,
  analyticsDownload,
  analyticsErrorLog,
  analyticsRemoveLocation,
} from "utils";
import { getExportData } from "components/common/MapboxMap/layers/EditableGeojsonLayer/utils";

export const AppStore = React.createContext<AppStoreType>({
  locations: [],
  exportType: "geojson",
  displayIntro: true,
  mapStyle: "spotify_dark",
  addLocation: () => { },
  updateLocations: () => { },
  removeLocation: () => { },
  exportMap: () => { },
  changeMapStyle: () => { },
  copyToClipboard: () => new Promise((resolve) => resolve(true)),
  clearLocations: () => { },
  changeExportType: () => { },
  toggleDisplayIntro: () => { },
  darkTheme: true,
});

interface AppStoreProvider {
  children: React.ReactNode;
}

export function AppStoreProvider(props: AppStoreProvider) {
  const [displayIntro, setDisplayIntro] = React.useState<boolean>(true);
  const [locations, setLocations] = React.useState<Location[]>([]);
  const [mapStyle, updateMapStyle] = React.useState(() => {
    if (typeof window !== "undefined") {
      const saved = localStorage?.getItem("mapStyle");
      if (saved) return saved;
    }
    return "spotify_dark";
  });

  const [exportType, setExportType] = React.useState<ExportType>(() => {
    if (typeof window !== "undefined") {
      const saved = localStorage.getItem("exportType");
      if (saved && ["geojson", "svg", "topojson"].includes(saved)) {
        return saved as ExportType;
      }
    }
    return "geojson";
  });

  const getLocation = React.useCallback(async (name: string, url: string) => {
    try {
      const geojson = await getGeojson(url);
      if ("features" in geojson) {
        setLocations((locations) =>
          locations.map((location) =>
            location.name === name
              ? {
                ...location,
                geojson: formatPropertiesIds(geojson),
                loading: false
              }
              : location
          )
        );
      }
    } catch (err) {
      analyticsErrorLog("getLocation");
    }
  }, []);

  const addLocation = React.useCallback(
    (name: string, url: string, extra?: Location) => {
      try {
        if (extra) analyticsAddLocation(extra);

        setLocations((locations) => {
          if (!locations.find((v) => v.name === name)) {
            return [
              ...locations,
              {
                id: name,
                name,
                url,
                loading: true,
                ...(extra || {}),
              } as Location,
            ];
          }
          return locations;
        });
        getLocation(name, url);
      } catch (err) {
        analyticsErrorLog("addLocation");
      }
    },
    []
  );

  const changeExportType = React.useCallback((type: ExportType) => {
    try {
      localStorage.setItem("exportType", type);
      setExportType(type);
    } catch (err) {
      analyticsErrorLog("changeExportType");
    }
  }, []);

  const changeMapStyle = React.useCallback((style: string) => {
    try {
      localStorage.setItem("mapStyle", style);
      updateMapStyle(style);
    } catch (err) {
      analyticsErrorLog("changeMapStyle");
    }
  }, []);

  const removeLocation = React.useCallback((name: string) => {
    try {
      setLocations((locations) => {
        const willRemove = locations.find((row) => row.name === name);
        if (willRemove) analyticsRemoveLocation(willRemove);
        return locations.filter((v) => !(v.name === name));
      });
    } catch (err) {
      analyticsErrorLog("removeLocation");
    }
  }, []);

  const clearLocations = React.useCallback(() => {
    try {
      analyticsClearLocations();
      setLocations([]);
    } catch (err) {
      analyticsErrorLog("clearLocations");
    }
  }, []);

  const copyToClipboard = React.useCallback(() => {
    return new Promise<boolean>((resolve) => {
      try {
        const availableLocations = locations.filter(
          (location) => !!location.geojson
        );
        const geojson = mergeGeojson(
          availableLocations.map((location) => location.geojson)
        );
        analyticsCopyToClipboard(exportType, availableLocations);
        switch (exportType) {
          case "geojson":
            resolve(copyTextToClipboard(JSON.stringify(geojson)));
            break;
          case "svg":
            const svgText = geojson2SVG(geojson);
            if (svgText) resolve(copyTextToClipboard(svgText));
            break;
          case "topojson":
            const topojsonText = geojson2Topojson(geojson);
            if (topojsonText) resolve(copyTextToClipboard(topojsonText));
            break;
        }
      } catch (err) {
        analyticsErrorLog("copyToClipboard");
        resolve(false);
      }
    });
  }, [locations, exportType]);

  const exportMap = React.useCallback(
    async () => {
      try {
        const availableLocations = locations.filter(
          (location) => !!location.geojson
        );
        const geojson = mergeGeojson(
          availableLocations.map((location) => getExportData(location.geojson))
        );
        analyticsDownload(exportType, availableLocations);
        switch (exportType) {
          case "geojson":
            downloadFile(JSON.stringify(geojson), "application/json", ".json");
            break;
          case "topojson":
            const topojsonText = geojson2Topojson(geojson);
            if (topojsonText)
              downloadFile(topojsonText, "application/json", ".json");
            break;
          case "svg":
            const svgText = geojson2SVG(geojson);
            if (svgText) downloadFile(svgText, "image/svg+xml", ".svg");
            break;
          case "png":
            const pngText = await geojson2Img(geojson, "image/png");
            if (pngText) downloadFile(pngText, "image/png", ".png");
            break;
          case "jpg":
            const jpgText = await geojson2Img(geojson, "image/jpg");
            if (jpgText) downloadFile(jpgText, "image/jpg", ".jpg");
            break;
        }
      } catch (err) {
        analyticsErrorLog("exportMap");
      }
    }, [locations, exportType]);

  React.useEffect(() => {
    try {
      getUserLocation().then((country) => {
        if (!locations.length) {
          addLocation(country, country);
        }
      });
    } catch (err) {
      analyticsErrorLog("getUserLocation");
    }
  }, []);

  const darkTheme = React.useMemo(() => {
    return mapStyle.toLowerCase().includes("dark");
  }, [mapStyle]);

  const contextValue = React.useMemo(() => {
    return {
      locations,
      exportType,
      mapStyle,
      addLocation,
      updateLocations: setLocations,
      removeLocation,
      exportMap,
      copyToClipboard,
      clearLocations,
      changeExportType,
      changeMapStyle,
      displayIntro,
      toggleDisplayIntro: setDisplayIntro,
      darkTheme,
    };
  }, [locations, exportType, displayIntro, mapStyle]);

  return (
    <AppStore.Provider value={contextValue}>{props.children}</AppStore.Provider>
  );
}
