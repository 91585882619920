import React from "react";

import "stylesheets/main.scss";
import "prismjs/themes/prism-tomorrow.css";

import { AppStoreProvider } from "store";
import { AuthStoreProvider } from "auth/AuthStore";

import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

// Wraps every page in a component
export function GatsbyWrapperPageElement(props: { element: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthStoreProvider>
        <AppStoreProvider>{props.element}</AppStoreProvider>
      </AuthStoreProvider>
    </QueryClientProvider>
  );
}
