import { geoBounds } from "d3";
import {
  RGFeature,
  RGFeatureCollection,
  RGLngLatBounds,
  RGGeometry,
  RGPoint
} from "types/geojson";
import transformRotate from "@turf/transform-rotate";
import transformScale from "@turf/transform-scale";
import { Position } from "@turf/helpers";
import { Location } from "store/types";
import { getRandomName } from "utils/names";
import { removeColorOpacity } from "./format";
import polylabel from "@mapbox/polylabel";
import { geoArea, GeoGeometryObjects } from "d3";

export function getRGFeaturesBounds(features: RGFeature[]): RGLngLatBounds {
  const bounds = features
    .map((feature) => geoBounds(feature))
    .map(
      (bound) =>
        [
          {
            lng: bound[0][0],
            lat: bound[0][1],
          },
          {
            lng: bound[1][0],
            lat: bound[1][1],
          },
        ] as RGLngLatBounds
    )
    .reduce((result: RGLngLatBounds, bound: RGLngLatBounds) => {
      if (!result) return bound;
      return [
        {
          lng: bound[0].lng < result[0].lng ? bound[0].lng : result[0].lng,
          lat: bound[0].lat < result[0].lat ? bound[0].lat : result[0].lat,
        },
        {
          lng: bound[1].lng > result[1].lng ? bound[1].lng : result[1].lng,
          lat: bound[1].lat > result[1].lat ? bound[1].lat : result[1].lat,
        },
      ];
      //just to avoid typing this
    }, null as any);

  const latSpan = (bounds[1].lat - bounds[0].lat) * 0.1;
  const lngSpan = (bounds[1].lng - bounds[0].lng) * 0.1;
  const maxSpan = Math.max(latSpan, lngSpan);

  const limit = (n: number, max: number, min: number) => {
    if (n < min) return min;
    if (n > max) return max;
    return n;
  };

  const limitBounds: RGLngLatBounds = [
    {
      lat: limit(bounds[0].lat - maxSpan, 90, -90),
      lng: limit(bounds[0].lng - maxSpan, 220, -220),
    },
    {
      lat: limit(bounds[1].lat + maxSpan, 90, -90),
      lng: limit(bounds[1].lng + maxSpan, 220, -220),
    },
  ];

  return limitBounds;
}

export function getGeoJSONBounds(data: RGFeatureCollection) {
  return getRGFeaturesBounds(data.features);
}

export function getGeoJSONsBounds(data: RGFeatureCollection[]) {
  return getRGFeaturesBounds(data.map((geojson) => geojson.features).flat());
}

const moveCoordinate = (coord: Position, delta: Position) => {
  const point = {
    lng: coord[0] + delta[0],
    lat: coord[1] + delta[1],
  };
  return [point.lng, point.lat] as Position;
};

const moveGeometry = (geometry: RGGeometry, delta: Position): RGGeometry => {
  switch (geometry.type) {
    case "Point":
      return {
        type: geometry.type,
        coordinates: moveCoordinate(geometry.coordinates, delta),
      };
    case "MultiPoint":
    case "LineString":
      return {
        type: geometry.type,
        coordinates: geometry.coordinates.map((p) => moveCoordinate(p, delta)),
      };
    case "MultiLineString":
    case "Polygon":
      return {
        type: geometry.type,
        coordinates: geometry.coordinates.map((circle) =>
          circle.map((p) => moveCoordinate(p, delta))
        ),
      };
    case "MultiPolygon":
      return {
        type: geometry.type,
        coordinates: geometry.coordinates.map((poly) =>
          poly.map((circle) => circle.map((p) => moveCoordinate(p, delta)))
        ),
      };
    default:
      return geometry;
  }
};

const scaleGeometry = (
  geometry: RGGeometry,
  scale: number,
  origin: Position
): RGGeometry => {
  const getDelta = (position: Position): Position => [
    (position[0] - origin[0]) * (scale - 1),
    (position[1] - origin[1]) * (scale - 1),
  ];
  switch (geometry.type) {
    case "Point":
      return {
        type: geometry.type,
        coordinates: moveCoordinate(
          geometry.coordinates,
          getDelta(geometry.coordinates)
        ),
      };
    case "MultiPoint":
    case "LineString":
      return {
        type: geometry.type,
        coordinates: geometry.coordinates.map((p) =>
          moveCoordinate(p, getDelta(p))
        ),
      };
    case "MultiLineString":
    case "Polygon":
      return {
        type: geometry.type,
        coordinates: geometry.coordinates.map((circle) =>
          circle.map((p) => moveCoordinate(p, getDelta(p)))
        ),
      };
    case "MultiPolygon":
      return {
        type: geometry.type,
        coordinates: geometry.coordinates.map((poly) =>
          poly.map((circle) =>
            circle.map((p) => moveCoordinate(p, getDelta(p)))
          )
        ),
      };
    default:
      return geometry;
  }
};

export function translateGeoJSON(
  geojson: RGFeatureCollection,
  delta: Position
) {
  return {
    type: geojson.type,
    features: geojson.features.map((feature) => {
      return {
        ...feature,
        geometry: feature.geometry
          ? moveGeometry(feature.geometry, delta)
          : feature.geometry,
      };
    }),
  };
}

export function translateGeoJSONFeature(feature: RGFeature, delta: Position) {
  return {
    ...feature,
    type: feature.type,
    geometry: feature.geometry
      ? moveGeometry(feature.geometry, delta)
      : feature.geometry,
  } as RGFeature;
}

export function scaleGeoJSON(
  geojson: RGFeatureCollection,
  factor: number,
  origin: Position
) {
  //@ts-ignore
  return transformScale(geojson, factor, {
    origin,
    mutate: false,
  }) as RGFeatureCollection;
}

export function scaleGeoJSONFeature(
  feature: RGFeature,
  factor: number,
  origin: Position
) {
  //@ts-ignore
  return transformScale(feature, factor, {
    origin,
    mutate: false,
  }) as RGFeature;
}

export function rotateGeoJSON(
  geojson: RGFeatureCollection,
  angle: number,
  pivot: Position
) {
  //@ts-ignore
  return transformRotate(geojson, angle, {
    pivot,
    mutate: false,
  }) as RGFeatureCollection;
}

export function rotateGeoJSONFeature(
  feature: RGFeature,
  angle: number,
  pivot: Position
) {
  //@ts-ignore
  return transformRotate(feature, angle, {
    pivot,
    mutate: false,
  }) as RGFeature;
}

export function featureToFeatureCollection(
  feature: RGFeature
): RGFeatureCollection {
  return {
    type: "FeatureCollection",
    features: [feature],
  };
}

export function getGeometryCoordinates(geometry: RGGeometry) {
  switch (geometry.type) {
    case "Point":
      return [geometry.coordinates];
    case "MultiPoint":
    case "LineString":
      return geometry.coordinates;
    case "MultiLineString":
    case "Polygon":
      return geometry.coordinates.flat();
    case "MultiPolygon":
      return geometry.coordinates.flat().flat();
    default:
      return [];
  }
}

export function mergeFeatures(
  locations: Location[],
  locationSelection: string[]
): Location[] {
  const filteredLocations = locations.filter((l) =>
    locationSelection.includes(l.id)
  );
  const name = getRandomName();
  const mergedFeatures =
    filteredLocations.length > 1 &&
    filteredLocations.reduce((acc, value) => {
      return {
        ...acc,
        id: name,
        name,
        geojson: {
          ...value.geojson,
          features: [
            ...(acc.geojson?.features || []),
            ...(value.geojson?.features.map((f) => {
              const featureName = getRandomName();
              return {
                ...f,
                id: featureName,
                properties: {
                  ...f.properties,
                  id: featureName,
                  name: featureName,
                }
              }
            }) || [])
          ]
        }
      } as Location;
    }, {} as Location);

  return [
    ...locations.filter((l) => !locationSelection.includes(l.id)),
    ...(mergedFeatures ? [mergedFeatures] : []),
  ];
}

export function unmergeFeatures(
  locations: Location[],
  locationSelection: string[]
): Location[] {
  const selectedLocations = locations.filter((l) =>
    locationSelection.includes(l.id)
  )[0];

  return [
    ...locations.filter((l) => !locationSelection.includes(l.id)),
    ...(selectedLocations.geojson?.features?.map((f: RGFeature) => {
      const name = getRandomName();
      return {
        id: name,
        name,
        geojson: {
          features: [
            {
              ...f,
              id: name,
              properties: {
                ...f.properties,
                _id: name,
                name,
              },
            },
          ],
          type: "FeatureCollection",
        },
      } as Location;
    }) || []),
  ];
}

export function temporaryMergeFeatures(
  locations: Location[],
  locationSelection: string[]
): Location[] {
  const filteredLocations = locations.filter((l) =>
    locationSelection.includes(l.id)
  );
  const name = getRandomName();
  const mergedFeatures =
    filteredLocations.length > 1 &&
    filteredLocations.reduce((acc, value) => {
      return {
        ...acc,
        id: name,
        name: name,
        geojson: {
          ...value.geojson,
          features: [
            ...(acc.geojson?.features || []),
            ...(value.geojson?.features.map((f) => f) || []),
          ],
        },
      } as Location;
    }, {} as Location);

  return [
    ...locations.filter((l) => !locationSelection.includes(l.id)),
    ...(mergedFeatures ? [mergedFeatures] : []),
  ];
}

export function updateFeaturesColor(
  locations: Location[],
  locationSelection: string[],
  featureSelection: string | undefined,
  color: string,
  opacity: number
) {
  return locations.map((location) => {
    if (locationSelection.includes(location.id)) {
      return {
        ...location,
        geojson: {
          ...location.geojson,
          features: location.geojson?.features.map((f) => {
            const systemStyle = f.properties["system-style"];
            if (featureSelection) {
              return {
                ...f,
                properties: {
                  ...f.properties,
                  "system-style": {
                    ...(f.id === featureSelection ? {
                      ...(color === 'transparent' ?
                        systemStyle && removeColorOpacity(systemStyle) :
                        {
                          ...systemStyle,
                          color,
                          opacity
                        }
                      )
                    } : systemStyle),
                  }
                }
              }
            }
            else {
              return {
                ...f,
                properties: {
                  ...f.properties,
                  "system-style": {
                    ...(color === 'transparent' ? {
                      ...(systemStyle && removeColorOpacity(systemStyle))
                    } : {
                      ...systemStyle,
                      color,
                      opacity
                    })
                  }
                }
              }
            }
          }),
        },
      } as Location;
    }
    return location;
  });
}

export function findPolylabel(feature: RGFeature) {
  if (feature.geometry.type === "Polygon") {
    return polylabel(feature.geometry.coordinates);
  }

  let maxArea = 0, maxPolygon: number | number[] = [];
  const geometry = feature.geometry as RGPoint;
  for (let i = 0, l = geometry.coordinates.length; i < l; i++) {
    const coordinates = geometry.coordinates[i];
    const area = geoArea({ type: "Polygon", coordinates } as unknown as GeoGeometryObjects);
    if (area > maxArea) {
      maxPolygon = coordinates;
      maxArea = area;
    }
  }
  return polylabel(maxPolygon);
}

export const getFeaturesPolylabel = (data: RGFeatureCollection): RGFeatureCollection => {
  return {
    ...data,
    features: data.features.map((f) => ({
      ...f,
      geometry: {
        'type': 'Point',
        'coordinates': findPolylabel(f)
      }
    }) as RGFeature
    )
  }
}


