import { FieldsErrors } from "hooks/api/types";
import { DISABLE_ANALYTICS } from "settings";
import { Location } from "store/types";

function callAnalytics(type: string, type1: string, data?: any) {
  if (!DISABLE_ANALYTICS) {
    //@ts-ignore
    typeof window !== "undefined" &&
      window.gtag &&
      window.gtag(type, type1, data);
  }
}

export function analyticsSearch(search: string) {
  try {
    callAnalytics("event", "search", { [search]: 1 });
  } catch (err) {}
}

export function analyticsAddLocation(data: Location) {
  try {
    if (data.root)
      callAnalytics("event", "locationsAdd", {
        [data.root]: `level_${data.level}`,
      });
  } catch (err) {}
}

export function analyticsRemoveLocation(data: Location) {
  try {
    if (data.root)
      callAnalytics("event", "locationsRemove", {
        [data.root]: `level_${data.level}`,
      });
  } catch (err) {}
}

export function analyticsClearLocations() {
  try {
    callAnalytics("event", "locationsClear");
  } catch (err) {}
}

export function analyticsDownload(format: string, data: Location[]) {
  try {
    callAnalytics("event", "download", {
      format,
      ...data.reduce(
        (r, l) => ({
          ...r,
          ...(l.root ? { [l.root]: `level_${l.level}` } : {}),
        }),
        {}
      ),
    });
  } catch (err) {}
}

export function analyticsCopyToClipboard(format: string, data: Location[]) {
  try {
    callAnalytics("event", "copyToClipboard", {
      format,
      ...data.reduce(
        (r, l) => ({
          ...r,
          ...(l.root ? { [l.root]: `level_${l.level}` } : {}),
        }),
        {}
      ),
    });
  } catch (err) {}
}

export function analyticsRegister() {
  try {
    callAnalytics("event", "register");
  } catch (err) {}
}

export function analyticsRegisterFailed(errors: FieldsErrors) {
  try {
    const data = Object.keys(errors).reduce(
      (r, key) => ({ ...r, [key]: errors[key]?.[0] }),
      {}
    );
    callAnalytics("event", "registerFailed", { ...data });
  } catch (err) {}
}

export function analyticsLogin() {
  try {
    callAnalytics("event", "login");
  } catch (err) {}
}

export function analyticsLoginFailed(errors: FieldsErrors) {
  try {
    const data = Object.keys(errors).reduce(
      (r, key) => ({ ...r, [key]: errors[key]?.[0] }),
      {}
    );
    callAnalytics("event", "loginLailed", { ...data });
  } catch (err) {}
}

export function analyticsErrorLog(error: string) {
  try {
    callAnalytics("event", "error", { [error]: 1 });
  } catch (err) {}
}
