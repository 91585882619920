import { analyticsErrorLog, analyticsLogin, analyticsLoginFailed } from "utils";
import { useAPI } from "hooks/api/useAPI";
import { postLogin } from "hooks/api/utils";
import { UserAccess } from "hooks/api/types";

export function useLogin() {
  return useAPI(postLogin, {
    initialState: () => {
      if (typeof window !== "undefined") {
        const token = localStorage?.getItem("token") || undefined;
        const accessString = localStorage?.getItem("access");
        if (accessString)
          return {
            token: token as string,
            access: JSON.parse(accessString) as UserAccess,
          };
      }
      return {
        token: undefined,
        access: undefined,
      };
    },
    onError: () => analyticsErrorLog("processLogin"),
    onFail: (errors) => {
      analyticsLoginFailed(errors);
    },
    onSuccess: (data) => {
      if (data?.token) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("access", JSON.stringify(data.access || {}));
        analyticsLogin();
      }
    },
  });
}
